// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 400px) {
  /* #features {
    padding: 20px;
    width: 111%;
  } */
  #about {
    padding: 20px;
    width: 111%;
  }
  /* #about, */
  #services,
  #testimonials,
  #team,
  #contact,
  #footer {
    width: 111%;
  }
  #portfolio {
    width: 110%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE;;;KAGG;EACH;IACE,aAAa;IACb,WAAW;EACb;EACA,YAAY;EACZ;;;;;IAKE,WAAW;EACb;EACA;IACE,WAAW;EACb;AACF","sourcesContent":["@media screen and (max-width: 400px) {\n  /* #features {\n    padding: 20px;\n    width: 111%;\n  } */\n  #about {\n    padding: 20px;\n    width: 111%;\n  }\n  /* #about, */\n  #services,\n  #testimonials,\n  #team,\n  #contact,\n  #footer {\n    width: 111%;\n  }\n  #portfolio {\n    width: 110%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
